body {
  margin: 0;
}

h1 {
  font-size: 2.5rem;
}

p {
  font-size: 1.25rem;
  font-weight: 300;
}

/* unvisited link */
a:link {
  color: white;
}

/* visited link */
a:visited {
  color: white;
}

/* mouse over link */
a:hover {
  color: #73D8F1;
}

/* selected link */
a:active {
  color: #0F4552;
}